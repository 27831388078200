.owl-theme .owl-nav {
    display: none;
}

@media (max-width: 600px) {
    .owl-carousel .owl-item {
        display: block;
        /* Stack items vertically */
    }

    .owl-carousel .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Center align items */
    }
}