.carousel-image {
    width: 100%;
    height: 100px;
    max-width: 150px; /* Adjust max width as needed */
    margin: 0 auto;
    display: block;
  }
  
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .owl-theme .owl-nav [class*='owl-']:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none;
  }
  