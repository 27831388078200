.dashboard-container {
    margin-top: 20px;
}

.sidebar {
    background-color: #f8f9fa;
    /* min-height: 100vh; */
    padding-top: 20px;
}

.sidebar .nav-link {
    margin-bottom: 10px;
    border-radius: 0;
}

.sidebar .nav-link.active {
    background-color: #007bff;
    color: white;
}

.tab-content {
    padding: 20px;
}
